const initNavicon = () => {

const checkbox = document.getElementById("toggle");
const dropUp = document.getElementById('drop-up-button');
const dropDown = document.getElementById('drop-down-button');

if (document.getElementById("toggle") !=null) {
  document.getElementById("toggle").addEventListener('change', function() {
      if (this.checked) {
      dropUp.classList.add("active");
      dropDown.classList.add("active");
    } else {
      dropUp.classList.remove("active");
      dropDown.classList.remove("active");
      }
    });
  }
}

export { initNavicon };
