const initBackToTopButton = () => {

  const scrollTop = document.getElementById('back-to-top-button');

  if (document.getElementById('back-to-top-button') !=null) {


    window.onscroll = () => {
      if (window.scrollY > 0) {
        scrollTop.style.visibility = "visible";
        scrollTop.style.opacity = 1;
      } else {
        scrollTop.style.visibility = "hidden";
        scrollTop.style.opacity = 0;
      }
    };
  }
}

export { initBackToTopButton };
