const initNavbar = () => {

if (document.getElementById("toggle") !=null) {
  document.getElementById("toggle").addEventListener('change', function() {
    if (this.checked) {
      if (document.querySelector('.content-container') !=null) {
        document.querySelector('.content-container').classList.add('active');
      }

    } else {
      if (document.querySelector('.content-container') !=null) {
        document.querySelector('.content-container').classList.remove('active');
      }

      }
    });
  }
}

export { initNavbar };
