import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import { initNavbar } from '../pages/init_navbar';
import { initNavicon } from '../pages/init_navicon';
import { initMapbox } from '../pages/init_mapbox';
import { initBackToTopButton } from '../pages/init_back_to_top_btn';


document.addEventListener('turbolinks:load', () => {
  initNavbar();
  initNavicon();
  initMapbox();
  initBackToTopButton();
})
